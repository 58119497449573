import {
  FiCopy,
  FiDatabase,
  FiNavigation,
  FiShoppingBag,
  FiUploadCloud,
  FiUsers,
} from 'react-icons/fi'
import { FileArrowUp, Copy } from 'phosphor-react'
import FortnoxIc from '~/common/components/icons/fortnox'

export const PROVIDERS_SLUGS = {
  TEXT: 'copy-paste',
  FILE: 'file',
  WIKINGGRUPPEN: 'wikinggruppen',
  FORTNOX: 'fortnox',
  STARWEB: 'starweb',
  JETSHOP: 'jetshop',
  LIMEGO: 'limego',
}

export const PROVIDERS_SLUGS_SERVER_MAP = {
  [PROVIDERS_SLUGS.TEXT]: PROVIDERS_SLUGS.TEXT,
  'copy-paste': 'text',
  [PROVIDERS_SLUGS.FILE]: PROVIDERS_SLUGS.FILE,
  [PROVIDERS_SLUGS.WIKINGGRUPPEN]: PROVIDERS_SLUGS.WIKINGGRUPPEN,
  [PROVIDERS_SLUGS.FORTNOX]: PROVIDERS_SLUGS.FORTNOX,
  [PROVIDERS_SLUGS.STARWEB]: PROVIDERS_SLUGS.STARWEB,
  [PROVIDERS_SLUGS.JETSHOP]: PROVIDERS_SLUGS.JETSHOP,
}

export const PROVIDERS_FIRST_STEP_MAP = {
  [PROVIDERS_SLUGS.TEXT]: '/contacts/import/text/destination',
  'copy-paste': '/contacts/import/text/destination',
  [PROVIDERS_SLUGS.FILE]: '/contacts/import/file/destination',
  [PROVIDERS_SLUGS.WIKINGGRUPPEN]: PROVIDERS_SLUGS.WIKINGGRUPPEN,
  [PROVIDERS_SLUGS.FORTNOX]: '/contacts/import/connect/fortnox',
  [PROVIDERS_SLUGS.STARWEB]: PROVIDERS_SLUGS.STARWEB,
  [PROVIDERS_SLUGS.JETSHOP]: PROVIDERS_SLUGS.JETSHOP,
}

export const PROVIDERS_ICONS = {
  [PROVIDERS_SLUGS.TEXT]: FiCopy,
  [PROVIDERS_SLUGS.COPY_PASTE]: FiCopy,
  [PROVIDERS_SLUGS.FILE]: FiUploadCloud,
  [PROVIDERS_SLUGS.WIKINGGRUPPEN]: FiUsers,
  [PROVIDERS_SLUGS.FORTNOX]: FiDatabase,
  [PROVIDERS_SLUGS.STARWEB]: FiShoppingBag,
  [PROVIDERS_SLUGS.JETSHOP]: FiNavigation,
}

export const NEW_PROVIDERS_ICONS = {
  [PROVIDERS_SLUGS.TEXT]: { component: Copy, props: { color: '#2278cf' } },
  [PROVIDERS_SLUGS.COPY_PASTE]: { component: Copy, props: { color: '#2278cf' } },
  [PROVIDERS_SLUGS.FILE]: { component: FileArrowUp, props: { color: '#2278cf' } },
  [PROVIDERS_SLUGS.WIKINGGRUPPEN]: FiUsers,
  [PROVIDERS_SLUGS.FORTNOX]: { component: FortnoxIc, props: { color: '#2278cf' } },
  [PROVIDERS_SLUGS.STARWEB]: FiShoppingBag,
  [PROVIDERS_SLUGS.JETSHOP]: FiNavigation,
}

export const PROVIDERS_DESCRIPTIONS = {
  [PROVIDERS_SLUGS.TEXT]: 'import.providers.description.copy-paste',
  [PROVIDERS_SLUGS.FILE]: 'import.providers.description.file',
  [PROVIDERS_SLUGS.WIKINGGRUPPEN]: 'import.providers.description.wikinggruppen',
  [PROVIDERS_SLUGS.FORTNOX]: 'import.providers.description.fortnox',
  [PROVIDERS_SLUGS.STARWEB]: 'import.providers.description.starweb',
  [PROVIDERS_SLUGS.JETSHOP]: 'import.providers.description.jetshop',
}

export const NEW_PROVIDERS_DESCRIPTIONS = {
  [PROVIDERS_SLUGS.TEXT]: 'import.file.overview.copyPaste.subtitle',
  [PROVIDERS_SLUGS.FILE]: 'import.file.overview.upload.subtitle',
  [PROVIDERS_SLUGS.WIKINGGRUPPEN]: 'import.providers.description.wikinggruppen',
  [PROVIDERS_SLUGS.FORTNOX]: 'import.file.overview.fortnox.subtitle',
  [PROVIDERS_SLUGS.STARWEB]: 'import.providers.description.starweb',
  [PROVIDERS_SLUGS.JETSHOP]: 'import.providers.description.jetshop',
  [PROVIDERS_SLUGS.LIMEGO]: 'import.file.overview.limeGo.subtitle',
}

export const PROVIDERS_NAMES = {
  [PROVIDERS_SLUGS.TEXT]: 'import.providers.name.copy-paste',
  [PROVIDERS_SLUGS.FILE]: 'import.providers.name.file',
  [PROVIDERS_SLUGS.WIKINGGRUPPEN]: 'import.name.description.wikinggruppen',
  [PROVIDERS_SLUGS.FORTNOX]: 'import.providers.name.fortnox',
  [PROVIDERS_SLUGS.STARWEB]: 'import.providers.name.starweb',
  [PROVIDERS_SLUGS.JETSHOP]: 'import.providers.name.jetshop',
}

export const PROVIDER_FORM_NAME = {
  [PROVIDERS_SLUGS.TEXT]: 'importCopyPasteNew',
  [PROVIDERS_SLUGS.FILE]: 'importFileNew',
  [PROVIDERS_SLUGS.FORTNOX]: 'importFortnox',
}

export const PLANS_LEVELS = {
  START: 10,
  STANDARD: 20,
  ENTERPRISE: 30,
}

export const TRIAL_PRIOD_STATUS = {
  NOT_USED: 'NOT_USED',
  USING: 'USING',
  USED: 'USED',
}

export const IMPORT_PLAN_LEVEL_NAMES = {
  [PLANS_LEVELS.START]: '',
  [PLANS_LEVELS.STANDARD]: 'plan.name.standard',
  [PLANS_LEVELS.ENTERPRISE]: 'plan.name.enterprise',
}

export const IMPORT_PLAN_LEVEL_NAMES_LONG = {
  [PLANS_LEVELS.START]: 'plan.name.start.long',
  [PLANS_LEVELS.STANDARD]: 'plan.name.standard.long',
  [PLANS_LEVELS.ENTERPRISE]: 'plan.name.enterprise.long',
}

export const CONTACT_IMPORT_STATUS = {
  WAITING_FOR_FILE: 0,
  VALIDATION_ERROR: 1,
  FILE_UPLOADED: 5,
  IN_QUEUE: 10,
  PROCESSING: 20,
  PROCESSING_ERROR: 25,
  COMPLETED: 30,
  USER_CANCELLED: 40,
}

export const mockProviders = [
  {
    name: 'Wikinggruppen',
    slug: PROVIDERS_SLUGS.WIKINGGRUPPEN,
    plan_level: PLANS_LEVELS.STANDARD,
    is_available: true,
  },
  {
    name: 'Fortnox',
    slug: PROVIDERS_SLUGS.FORTNOX,
    plan_level: PLANS_LEVELS.STANDARD,
    is_available: true,
  },
  {
    name: 'Starweb',
    slug: PROVIDERS_SLUGS.STARWEB,
    plan_level: PLANS_LEVELS.ENTERPRISE,
    is_available: true,
  },
  {
    name: 'Jetshop',
    slug: PROVIDERS_SLUGS.JETSHOP,
    plan_level: PLANS_LEVELS.ENTERPRISE,
    is_available: true,
  },
]

export const defaultProviders = [
  {
    name: 'Copy/Paste',
    slug: PROVIDERS_SLUGS.TEXT,
    plan_level: PLANS_LEVELS.START,
    is_available: true,
    is_default: true,
  },
  {
    name: 'Upload file',
    slug: PROVIDERS_SLUGS.FILE,
    plan_level: PLANS_LEVELS.START,
    is_available: true,
    is_default: true,
  },
  // {
  //   name: 'Fortnox',
  //   slug: PROVIDERS_SLUGS.FORTNOX,
  //   plan_level: PLANS_LEVELS.STANDARD,
  //   is_available: true,
  //   is_default: false,
  //   is_new: true,
  //   requires_connection: true,
  // },
]

export const mockConnections = [
  {
    provider_slug: PROVIDERS_SLUGS.WIKINGGRUPPEN,
    connected_at: new Date().toISOString(),
  },
]

export const mockHistoryItems = [
  {
    provider_slug: PROVIDERS_SLUGS.FORTNOX,
    provider_plan_level: PLANS_LEVELS.STANDARD,
    url: 'https://api.gantest.com/v3/contacts/import/file/P2E1UzULt/',
    hash: 'P2E1UzULt',
    lists: [
      {
        hash: 'Nnct2rHrH',
        name: 'test',
      },
      {
        hash: 'Nnct2rHrH',
        name: 'test',
      },
    ],
    mapping: {
      contact: {
        email: 'Email',
        first_name: 'Name',
      },
      attributes: {
        city: 'City',
      },
    },
    mode: 1,
    delimiter: ';',
    quotechar: '"',
    escapechar: '\\',
    status: CONTACT_IMPORT_STATUS.COMPLETED,
    errors_file: null,
    summary: {
      'contacts created': 0,
      'contacts updated': 0,
      'subscriptions created': 21,
    },
    errors_count: 0,
    progress: 100,
    warnings: [],
  },
  {
    provider_slug: PROVIDERS_SLUGS.FORTNOX,
    provider_plan_level: PLANS_LEVELS.START,
    url: 'https://api.gantest.com/v3/contacts/import/file/P2E1UzULt/',
    hash: 'P2E1UzULt',
    lists: [
      {
        hash: 'Nnct2rHrH',
        name: 'test',
      },
      {
        hash: 'Nnct2rHrH',
        name: 'test',
      },
    ],
    mapping: {
      contact: {
        email: 'Email',
        first_name: 'Name',
      },
      attributes: {
        city: 'City',
      },
    },
    mode: 1,
    delimiter: ';',
    quotechar: '"',
    escapechar: '\\',
    status: CONTACT_IMPORT_STATUS.FILE_UPLOADED,
    errors_file: null,
    summary: {
      'contacts created': 0,
      'contacts updated': 0,
      'subscriptions created': 21,
    },
    errors_count: 0,
    progress: 100,
    warnings: [],
  },
  {
    provider_slug: PROVIDERS_SLUGS.FORTNOX,
    provider_plan_level: PLANS_LEVELS.ENTERPRISE,
    url: 'https://api.gantest.com/v3/contacts/import/file/P2E1UzULt/',
    hash: 'P2E1UzULt',
    lists: [
      {
        hash: 'Nnct2rHrH',
        name: 'test',
      },
      {
        hash: 'Nnct2rHrH',
        name: 'test',
      },
    ],
    mapping: {
      contact: {
        email: 'Email',
        first_name: 'Name',
      },
      attributes: {
        city: 'City',
      },
    },
    mode: 1,
    delimiter: ';',
    quotechar: '"',
    escapechar: '\\',
    status: CONTACT_IMPORT_STATUS.IN_QUEUE,
    errors_file: null,
    summary: {
      'contacts created': 0,
      'contacts updated': 0,
      'subscriptions created': 21,
    },
    errors_count: 0,
    progress: 100,
    warnings: [],
  },
  {
    provider_slug: PROVIDERS_SLUGS.FORTNOX,
    provider_plan_level: PLANS_LEVELS.ENTERPRISE,
    url: 'https://api.gantest.com/v3/contacts/import/file/P2E1UzULt/',
    hash: 'P2E1UzULt',
    lists: [
      {
        hash: 'Nnct2rHrH',
        name: 'test',
      },
      {
        hash: 'Nnct2rHrH',
        name: 'test',
      },
    ],
    mapping: {
      contact: {
        email: 'Email',
        first_name: 'Name',
      },
      attributes: {
        city: 'City',
      },
    },
    mode: 1,
    delimiter: ';',
    quotechar: '"',
    escapechar: '\\',
    status: CONTACT_IMPORT_STATUS.PROCESSING,
    errors_file: null,
    summary: {
      'contacts created': 0,
      'contacts updated': 0,
      'subscriptions created': 21,
    },
    errors_count: 0,
    progress: 100,
    warnings: [],
  },
  {
    provider_slug: PROVIDERS_SLUGS.FORTNOX,
    provider_plan_level: PLANS_LEVELS.ENTERPRISE,
    url: 'https://api.gantest.com/v3/contacts/import/file/P2E1UzULt/',
    hash: 'P2E1UzULt',
    lists: [
      {
        hash: 'Nnct2rHrH',
        name: 'test',
      },
      {
        hash: 'Nnct2rHrH',
        name: 'test',
      },
    ],
    mapping: {
      contact: {
        email: 'Email',
        first_name: 'Name',
      },
      attributes: {
        city: 'City',
      },
    },
    mode: 1,
    delimiter: ';',
    quotechar: '"',
    escapechar: '\\',
    status: CONTACT_IMPORT_STATUS.PROCESSING_ERROR,
    errors_file: null,
    summary: {
      'contacts created': 0,
      'contacts updated': 0,
      'subscriptions created': 21,
    },
    errors_count: 0,
    progress: 100,
    warnings: [],
  },
  {
    provider_slug: PROVIDERS_SLUGS.FORTNOX,
    provider_plan_level: PLANS_LEVELS.ENTERPRISE,
    url: 'https://api.gantest.com/v3/contacts/import/file/P2E1UzULt/',
    hash: 'P2E1UzULt',
    lists: [
      {
        hash: 'Nnct2rHrH',
        name: 'test',
      },
      {
        hash: 'Nnct2rHrH',
        name: 'test',
      },
    ],
    mapping: {
      contact: {
        email: 'Email',
        first_name: 'Name',
      },
      attributes: {
        city: 'City',
      },
    },
    mode: 1,
    delimiter: ';',
    quotechar: '"',
    escapechar: '\\',
    status: CONTACT_IMPORT_STATUS.USER_CANCELLED,
    errors_file: null,
    summary: {
      'contacts created': 0,
      'contacts updated': 0,
      'subscriptions created': 21,
    },
    errors_count: 0,
    progress: 100,
    warnings: [],
  },
  {
    provider_slug: PROVIDERS_SLUGS.FORTNOX,
    provider_plan_level: PLANS_LEVELS.ENTERPRISE,
    url: 'https://api.gantest.com/v3/contacts/import/file/P2E1UzULt/',
    hash: 'P2E1UzULt',
    lists: [
      {
        hash: 'Nnct2rHrH',
        name: 'test',
      },
      {
        hash: 'Nnct2rHrH',
        name: 'test',
      },
    ],
    mapping: {
      contact: {
        email: 'Email',
        first_name: 'Name',
      },
      attributes: {
        city: 'City',
      },
    },
    mode: 1,
    delimiter: ';',
    quotechar: '"',
    escapechar: '\\',
    status: CONTACT_IMPORT_STATUS.VALIDATION_ERROR,
    errors_file: null,
    summary: {
      'contacts created': 0,
      'contacts updated': 0,
      'subscriptions created': 21,
    },
    errors_count: 0,
    progress: 100,
    warnings: [],
  },
  {
    provider_slug: PROVIDERS_SLUGS.WIKINGGRUPPEN,
    provider_plan_level: PLANS_LEVELS.ENTERPRISE,
    url: 'https://api.gantest.com/v3/contacts/import/file/P2E1UzULt/',
    hash: 'P2E1UzULt',
    lists: [
      {
        hash: 'Nnct2rHrH',
        name: 'test',
      },
      {
        hash: 'Nnct2rHrH',
        name: 'test',
      },
    ],
    mapping: {
      contact: {
        email: 'Email',
        first_name: 'Name',
      },
      attributes: {
        city: 'City',
      },
    },
    mode: 1,
    delimiter: ';',
    quotechar: '"',
    escapechar: '\\',
    status: CONTACT_IMPORT_STATUS.WAITING_FOR_FILE,
    errors_file: null,
    summary: {
      'contacts created': 0,
      'contacts updated': 0,
      'subscriptions created': 21,
    },
    errors_count: 0,
    progress: 100,
    warnings: [],
  },
  {
    provider_slug: PROVIDERS_SLUGS.TEXT,
    provider_plan_level: PLANS_LEVELS.ENTERPRISE,
    url: 'https://api.gantest.com/v3/contacts/import/file/P2E1UzULt/',
    hash: 'P2E1UzULt',
    lists: [
      {
        hash: 'Nnct2rHrH',
        name: 'test',
      },
      {
        hash: 'Nnct2rHrH',
        name: 'test',
      },
    ],
    mapping: {
      contact: {
        email: 'Email',
        first_name: 'Name',
      },
      attributes: {
        city: 'City',
      },
    },
    mode: 1,
    delimiter: ';',
    quotechar: '"',
    escapechar: '\\',
    status: CONTACT_IMPORT_STATUS.COMPLETED,
    errors_file: null,
    summary: {
      'contacts created': 0,
      'contacts updated': 0,
      'subscriptions created': 21,
    },
    errors_count: 0,
    progress: 100,
    warnings: [],
  },
  {
    provider_slug: PROVIDERS_SLUGS.STARWEB,
    provider_plan_level: PLANS_LEVELS.ENTERPRISE,
    url: 'https://api.gantest.com/v3/contacts/import/file/P2E1UzULt/',
    hash: 'P2E1UzULt',
    lists: [
      {
        hash: 'Nnct2rHrH',
        name: 'test',
      },
      {
        hash: 'Nnct2rHrH',
        name: 'test',
      },
    ],
    mapping: {
      contact: {
        email: 'Email',
        first_name: 'Name',
      },
      attributes: {
        city: 'City',
      },
    },
    mode: 1,
    delimiter: ';',
    quotechar: '"',
    escapechar: '\\',
    status: CONTACT_IMPORT_STATUS.COMPLETED,
    errors_file: null,
    summary: {
      'contacts created': 0,
      'contacts updated': 0,
      'subscriptions created': 21,
    },
    errors_count: 0,
    progress: 100,
    warnings: [],
  },
  {
    provider_slug: PROVIDERS_SLUGS.JETSHOP,
    provider_plan_level: PLANS_LEVELS.ENTERPRISE,
    url: 'https://api.gantest.com/v3/contacts/import/file/P2E1UzULt/',
    hash: 'P2E1UzULt',
    lists: [
      {
        hash: 'Nnct2rHrH',
        name: 'test',
      },
      {
        hash: 'Nnct2rHrH',
        name: 'test',
      },
    ],
    mapping: {
      contact: {
        email: 'Email',
        first_name: 'Name',
      },
      attributes: {
        city: 'City',
      },
    },
    mode: 1,
    delimiter: ';',
    quotechar: '"',
    escapechar: '\\',
    status: CONTACT_IMPORT_STATUS.COMPLETED,
    errors_file: null,
    summary: {
      'contacts created': 0,
      'contacts updated': 0,
      'subscriptions created': 21,
    },
    errors_count: 0,
    progress: 100,
    warnings: [],
  },
]
