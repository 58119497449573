import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { CheckCircle, X } from 'phosphor-react'
import { Dialog, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom'

import Image from '~/components/Image'

import authLoginEditorImg from '~/img/trial-start.png'

const accessIntlIdList = [
  'startTrial.access.landingAndSurveys',
  'startTrial.access.templates',
  'startTrial.access.emails',
]

function ModalStartedTrial({ isOpen, actionLink, actionLabel, onClose }) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-30" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="flex w-full max-w-3xl scale-100 transform overflow-hidden rounded-lg bg-white text-left align-middle opacity-100 shadow-xl transition-all md:rounded-2xl">
                <div className="flex-1">
                  <div className="px-10 py-14">
                    <h2>
                      <FormattedMessage id="startTrial.title" />
                    </h2>
                    <p className="mt-3 font-medium text-gray-600">
                      <FormattedMessage id="startTrial.description" />
                    </p>
                    <div className="mt-3 divide-y divide-gray-100">
                      {accessIntlIdList.map((accessIntlId) => (
                        <div key={accessIntlId} className="flex items-center gap-2 py-2">
                          <CheckCircle
                            className="fill-emerald-600 text-emerald-600"
                            weight="duotone"
                            size={20}
                          />
                          <span className="flex-1 text-gray-900">
                            <FormattedMessage id={accessIntlId} />
                          </span>
                        </div>
                      ))}
                    </div>
                    {!!actionLabel && !!actionLink && (
                      <div className="mt-4">
                        <Link
                          to={actionLink}
                          className="btn btn-success w-full bg-emerald-600 leading-5"
                          onClick={onClose}
                        >
                          {actionLabel}
                        </Link>
                      </div>
                    )}
                    {(!actionLabel || !actionLink) && (
                      <div className="mt-4">
                        <button
                          className="btn btn-success w-full bg-emerald-600 leading-5"
                          onClick={onClose}
                        >
                          <FormattedMessage id="startTrial.button" />
                        </button>
                      </div>
                    )}
                    <p className="mt-4 text-gray-600">
                      <FormattedMessage id="startTrial.note" />
                    </p>
                  </div>
                </div>
                <div className="relative flex-1 bg-gray-100">
                  <Image className="h-full w-full object-cover" image={authLoginEditorImg} alt="" />
                  <button
                    className="absolute top-3 right-3 p-1 text-gray-500 hover:text-gray-900"
                    onClick={onClose}
                  >
                    <X weight="bold" size={16} />
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
        <button className="absolute bottom-0 opacity-0" />
      </Dialog>
    </Transition>
  )
}

export default ModalStartedTrial
